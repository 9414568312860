import React from 'react';
import {Link} from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from './projects.module.css';

const ProjectsPage = () => (
  <Layout>
    <SEO title="Projects" />
    <h2>Projects</h2>
    <p>
      This list contains a handful of projects and side hustles I&#39;ve worked on over the years.
    </p>
    <Project
      name="Hacker Paper"
      url="https://www.hackerpaper.com"
      summary="Hacker Paper is the journal trusted by hackers, hustlers, and people who make stuff."
      purpose="I've kept a digital journal for a while now. Hacker Paper is a 'productized' version of my journalling process. I use it almost daily (#dogfood)."
      technology="Django, React"
      status="I am actively making time to work on this (as of April 1, 2020). Please let me know if what you think via Twitter DM."
    >
      PS - I've been livestreaming some of my work on Hacker Paper which you can watch on <a href="https://www.youtube.com/user/HolyDropBear">my YouTube channel</a>.

    </Project>
    <Project
      name="Devour"
      url="https://getdevour.com"
      summary="Devour is a mobile web app that helps you stick to various nutrition plans. Devour makes it easy to visualize your unique nutritional needs."
      purpose="Many nutrition plans are incredibly detailed. The information is excellent but the spreadsheets and text files they provide are difficult to scan. Referencing a specific meal can be tedious and don&#39;t even bother trying on your phone.  Devour solves this problem by displaying the entire plan in a filterable and easy to scan format."
      technology="React, Redux, Firebase, Ruby on Rails"
      status="Devour is humming along with triple digit active users, although I haven't invested in new features since mid-2019."
    />
    <Project
      name="ZenPhotos"
      url=""
      summary="ZenPhotos was a web-based platform for professional photographers to create and share digital albums with their clients. The photographer creates an album, uploads photos, customizes the branding, and optionally sets up password protection. Moments after they hit Publish they had a URL to share with their clients who could view the album on any device."
      purpose="The digital photo album space is filled with expensive and ugly services. ZenPhotos aimed to be a blazing fast, low cost, and easy to use competitor. Our team utilized modern technology to reduce storage and server costs (full resolution photos are huge these days). Our UI also allowed users to go from zero to published album in fewer steps than our peers."
      technology="Angular 1, ASP.NET Web API, AWS S3, AWS Lambda, Varnish"
      status="Unfortunately ZenPhotos had to be shut down in mid-2016. Ask me for details and we can grab a beer."
    />
    <Project
      name="Plot Shift"
      url="https://plotshift.com"
      summary="Plot Shift is a collaborative writing platform where stories evolve based on the most popular user-submitted variations to the story. One person starts the story and other people submit their own variations to the story. Everyone votes on their favorite variation and the most popular one gets &quot;locked in.&quot; The process repeats itself until the story is finished."
      purpose="Plot Shift began as my undergraduate capstone project and followed me for years after. This was my first attempt at bringing a &quot;product&quot; to market and my first taste of product management."
      technology="ASP.NET MVC, MS SQL Server, jQuery"
      status="I recently sunsetted Plot Shift in order to narrow my focus on current projects...also nobody was still using it in 2018. It lived a good life, though."
    >
      PS - Plot Shift was <a href='http://www.producthunt.com/posts/plot-shift'>featured on Product Hunt</a> before it was cool.
    </Project>
    <Project
      name="Modern Smart Home"
      url="http://modernsmarthome.com"
      summary="Modern Smart Home is a digital publication covering various smart home topics."
      purpose="The smart home landscape is complex and rapidly changing. There are competing standards and compatibility issues galore. Modern Smart Home aims to distill this mess of information for readers who are interested but are not extremely technical DIYers."
      technology="iBeacon, Swift"
      status="I've taken a break from writing since mid-2016 but the site is alive and well."
    />
  </Layout>
);

const Project = (props) => {
  return (
    <div className={styles.project}>
      <div className={styles.projectName}>{props.name}</div>
      {props.url &&
        <div className={styles.projectLink}>
          <a href={props.url}>{props.url}</a>
        </div>
      }
      <div className={styles.projectSection}>
        <div className={styles.title}>Summary</div>
        <div>{props.summary}</div>
      </div>
      <div className={styles.projectSection}>
        <div className={styles.title}>Purpose</div>
        <div>{props.purpose}</div>
      </div>
      <div className={styles.projectSection}>
        <div className={styles.title}>Key Technology</div>
        <div>{props.technology}</div>
      </div>
      <div className={styles.projectSection}>
        <div className={styles.title}>Status</div>
        <div>{props.status}</div>
      </div>
      {props.children && <div>{props.children}</div>}
    </div>
  );
};

export default ProjectsPage;
